import { createStore } from "vuex";
import EventService from "@/services/EventService.js";

export default createStore({
  state: {
    events: [],
    event: {},
  },
  mutations: {
    ADD_TO_EVENT_LIST(state, event) {
      state.events.push(event);
    },
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_EVENT(state, event) {
      state.event = event;
    },
  },
  actions: {
    addEvent({ commit }, event) {
      EventService.postEvent(event)
        .then(() => {
          // add to vuex store
          commit("ADD_TO_EVENT_LIST", event); //removed this.$store.
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchEvents({ commit }) {
      EventService.getEvents()
        .then((response) => {
          console.log(response.data);
          commit("SET_EVENTS", response.data);
        })
        .catch((error) => console.log(error));
    },

    fetchEvent({ commit, state }, id) {
      //adding state to check if values exist in state for performace boost
      const existingEvent = state.events.find((event) => event.id === id);
      if (existingEvent) {
        commit("SET_EVENT", existingEvent);
      } else {
        EventService.getEvent(id)
          .then((response) => {
            commit("SET_EVENT", response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  modules: {},
});
