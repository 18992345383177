<template>
  <div v-if="event">
    <!-- Wait for the components to load the event Object before it displays  -->
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
export default {
  props: ["id"],

  created() {
    this.$store.dispatch("fetchEvent", this.id);
  },
  computed: {
    event() {
      return this.$store.state.event;
    },
  },
};
</script>
