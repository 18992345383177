<template>
  <div class="events">
    <div>
      <EventCard v-for="event in events" :key="event.id" :event="event" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
//import axios from "axios";
//import EventService from "@/services/EventService.js";

export default {
  name: "EventList",
  components: {
    EventCard,
  },

  

  created() {
    this.$store.dispatch("fetchEvents"); //call to the action
  },

  computed: {
    events() {
      return this.$store.state.events;
    }
  }
};
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
